import {defineStore} from "pinia";
import {useSocket} from "~/store/socketStore";
import {useController} from "~/store/controllerStore";
import {useTournament} from "~/store/tournamentStore";
import {api} from "~/composables/api";
import {useBank} from "~/store/bankStore";
import {MainStoreState} from "~/types/MainStoreData";
import {ProfileData} from "~/types/ProfileData";
import {ResponseData} from "~/types/ReponseData";

export const useMainStore = defineStore("mainStore", {
    state: (): MainStoreState => {
        return {
            urlQueryPrefix: '',
            referral: '',
            referralBy: '',
            isLogin: false,
            agentPrefix: '',
            namePrefix: '',
            cashbackConfig: {},
            referralConfig: {},
            lineSupport: '',
            lineTournament: '',
            // avatar: '',
            avatar: '/logo/logo.gif',
            logoLoadingURL: 'https://upbetx.s3.ap-southeast-1.amazonaws.com/images/game-app/loading.gif',
            appBandLink: '',
            loadingProfile: true,
            loadCredit: false,
            ecoMode: false,
            balance: {
                credit: 0,
                diamond: 0,
                coin: '',
            },
            statusReceivePromotion: false,
            promotionData: {},
            profile: {
                avatar: "",
                nickName: "",
                otpVerifyStatus: "",
                mode: "",
                tier: "",
                registerStep: 0,
                createdAt: 0,
                prefix: "",
                customerStatus: "",
                updatedBy: "",
                fullName: "",
                referral: "",
                createdBy: "",
                customerType: "",
                pin: "",
                updatedAt: 0,
                bankVerifyStatus: "",
                phone: "",
                customerId: ""
            },
            notifyData: null,
        }
    },

    getters: {
        getProfileData(): ProfileData {
            return this.profile
        }
    },

    actions: {
        setCashbackConfig(cashbackConfig: any): void {
            this.cashbackConfig = cashbackConfig
        },
        setReferralConfig(referralConfig: any): void {
            this.referralConfig = referralConfig
        },
        setProfileAvatar(avatar: string): void {
            this.profile.avatar = avatar
        },

        setProfileNickname(nickname: string): void {
            this.profile.nickName = nickname
        },

        setReferral(referral: string): void {
            this.referral = referral || localStorage.getItem('referral') || ''
        },

        setReferralBy(referralBy: string): void {
            this.referralBy = referralBy || localStorage.getItem('referralBy') || ''
        },

        setUrlQueryPrefix(prefix: string): void {
            this.urlQueryPrefix = prefix?.toUpperCase() || localStorage.getItem('agentPrefix') || ''
            localStorage.setItem('urlQueryPrefix', prefix?.toUpperCase())
        },

        resetState(): void {
            this.isLogin = false
            this.loadingProfile = true
            this.balance = {
                credit: 0,
                diamond: 0,
                coin: '',
            }
            this.profile = {
                avatar: "",
                nickName: "",
                otpVerifyStatus: "",
                mode: "",
                tier: "",
                registerStep: 0,
                createdAt: 0,
                prefix: "",
                customerStatus: "",
                updatedBy: "",
                fullName: "",
                referral: "",
                createdBy: "",
                customerType: "",
                pin: "",
                updatedAt: 0,
                bankVerifyStatus: "",
                phone: "",
                customerId: ""
            }
        },

        setEchoMode(ecoMode: any): void {
            this.ecoMode = ecoMode
            localStorage.setItem('ecoMode', ecoMode)
        },

        setLogoLoadingURL(url: string): void {
            this.logoLoadingURL = this.urlQueryPrefix === 'UBX' ? 'https://upbetx.s3.ap-southeast-1.amazonaws.com/images/game-app/loading.gif' : url
        },

        async getPrefix(): Promise<ResponseData> {
            const {data, success} = await api.get({
                path: '/agent/' + this.urlQueryPrefix,
            });
            if (success && data) {
                localStorage.setItem('agentPrefix', data.prefix)
                localStorage.setItem('urlQueryPrefix', data.prefix)
                this.agentPrefix = data.prefix
                this.lineSupport = data.lineSupport
                this.avatar = data.imgUrl
                this.namePrefix = data.prefix
                this.setCashbackConfig(data.cashbackConfig)
                this.setReferralConfig(data.referralConfig)
                this.lineTournament = data.lineTournamentSupport
                this.appBandLink = data.appBandLink
                this.setLogoLoadingURL(data.imgUrl)
            } else {
                localStorage.removeItem('agentPrefix')
                localStorage.removeItem('urlQueryPrefix')
                localStorage.removeItem('referral')
                this.agentPrefix = ''
            }
            return {data, success} as ResponseData
        },

        async getProfile(): Promise<void> {
            this.loadingProfile = true
            const tournament = useTournament()
            const socket = useSocket()
            const controller = useController()
            const bank = useBank()
            const {data, success}: ResponseData = await api.get({
                path: '/users/profile',
            });
            controller.getSetting()
            tournament.setIsRegisterTournament(data.isRegisterTournament)
            if (success) {
                this.profile = data.customer
                this.statusReceivePromotion = data.customer?.tier === 'newUser' ? false : true
                this.promotionData = data?.promotions
                bank.setDataBankVerify(data.bank)
                this.isLogin = true
                localStorage.setItem('isLogin', 'y')
                await this.getCredit()
                socket.connectSocket()
                this.loadingProfile = false
            } else {
                localStorage.setItem('isLogin', 'n')
                localStorage.removeItem('access_token')
                this.loadingProfile = true
            }
        },

        async getCredit(): Promise<string> {
            if (localStorage.getItem('access_token')) {
                this.loadCredit = true
                const {data = {}, success} = await api.get({
                    path: '/users/credit',
                });
                if (success) {
                    this.balance = {
                        credit: data.gameCredit,
                        diamond: data.diamond,
                        coin: data.tournamentCredit,
                    }
                }
                setTimeout((): void => {
                    this.loadCredit = false
                }, 2500)
                return success
            }
            return ''
        },

        async editNickname(nickname: string, avatar: number): Promise<ResponseData> {
            return await api.put({
                path: '/users/profile',
                body: {
                    nickName: nickname,
                    avatar: avatar.toString()
                }
            });

        },

        async countClickLink() {
            return await api.get({
                path: '/users/click',
                query: {
                    referralBy: this.referralBy,
                    referral: this.referral,
                }
            })
        },


        async getNotify(): Promise<ResponseData> {
            const prefix: string = localStorage.getItem('agentPrefix') || ''
            if (prefix) {
                const {data, success}: ResponseData = await api.get({
                    path: '/notify/' + prefix,
                })
                if (success) {
                    this.notifyData = data
                }
                return {data, success} as ResponseData
            } else {
                return {data: [], success: false} as ResponseData
            }
        },

        async syncWallet(): Promise<ResponseData> {
            return await api.get({
                path: '/migrates',
            })
        }
    }
})
