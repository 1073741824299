import { ref, computed, useHead } from "#imports"
import { defineNuxtPlugin } from "#app"
import Quasar from "quasar/src/vue-plugin.js"
import Loading from "quasar/src/plugins/Loading.js"
import Notify from "quasar/src/plugins/Notify.js"
import lang from "quasar/lang/th"
import iconSet from "quasar/icon-set/material-icons"

export default defineNuxtPlugin((nuxt) => {




  nuxt.vueApp.use(Quasar, {
    lang,
    iconSet,
    plugins: {Loading,Notify},
    config: {"loading":{"spinnerSize":0,"boxClass":"page-loader","html":true},"notify":{"position":"top-right"}},
  })
})