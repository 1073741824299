import axios from "axios";

const www = import.meta.env.VITE_API_URL;

const clearLocalStorage = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('isLogin')
    localStorage.removeItem('unReadNews')
    window.location.href = '/'
}


const api = {
    async post(payload) {
        try {
            let token = localStorage.getItem("access_token");
            const {path, body} = payload;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            return await new Promise((resolve) => {
                axios.post(`${www}${path}`, body, config).then(async (res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("POST ERROR : ", err);
                    if (err.response.status === 401 || err.response.status === 403) {
                        clearLocalStorage()
                        resolve("unAuthorized");
                    }
                });
            });
        } catch (err) {
            return "POST ERROR";
        }
    },

    async get(payload) {
        try {
            let token = localStorage.getItem("access_token");
            const {path, params = '', query = {}} = payload;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
            return await new Promise((resolve) => {
                const url = `${www}${path}${params || params === 0 ? `/${params}?${queryString}` : `${queryString?.length > 0 ? `?${queryString}` : ''}`}`;
                axios.get(url, config).then(async (res) => {
                    resolve(res.data);
                }).catch((err) => {
                    if (err.response.status === 401 || err.response.status === 403) {
                        clearLocalStorage()
                        resolve("unAuthorized");
                    }
                });
            });
        } catch (err) {
            return "GET ERROR";
        }
    },

    async put(payload) {
        try {
            let token = localStorage.getItem("access_token");
            const {path, body} = payload;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            return await new Promise((resolve) => {
                axios.put(`${www}${path}`, body, config).then(async (res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("POST ERROR : ", err);
                    if (err.response.status === 401 || err.response.status === 403) {
                        clearLocalStorage()
                        resolve("unAuthorized");
                    }
                });
            });
        } catch (err) {
            return "POST ERROR";
        }
    },
};

export {api};
