import {defineStore} from "pinia";
import {useMainStore} from "~/store/mainStore";

export const useSocket = defineStore("socketStore", {
    state: () => {
        return {
            socket: null,
            alertTrigger: false,
            dataAlert: null,
        }
    },
    actions: {
        closeAlert() {
            const button = document.getElementsByClassName('q-btn q-btn-item non-selectable no-outline q-btn--flat q-btn--rectangle q-btn--actionable q-focusable q-hoverable')[0]
            if (button) {
                button.click()
            }
        },


        triggerAlert() {
            this.dataAlert = {
                success: true,
                message: 'test message',
            }
            this.alertTrigger = !this.alertTrigger
        },

        connectSocket() {
            const mainStore = this.initMainStore();

            const webSocket = new WebSocket(import.meta.env.VITE_SOCKET_URL);
            // @ts-ignore
            this.socket = webSocket

            webSocket.onopen = (event) => {
                webSocket.send(<string>localStorage.getItem("access_token"))
            }

            webSocket.onmessage = (event) => {
                mainStore.getCredit()
                this.dataAlert = JSON.parse(event.data)
                this.alertTrigger = !this.alertTrigger
            }

            webSocket.onclose = (event) => {
                this.connectSocket()
            }

            webSocket.onerror = (event) => {
                console.log('Socket Error !')
            }
        },




        // connectSocket2() {
        //
        //     const mainStore = this.initMainStore();
        //
        //     const webSocket = new WebSocket(import.meta.env.VITE_SOCKET_URL);
        //
        //     this.socket = webSocket
        //
        //     webSocket.onopen = (event) => {
        //         webSocket.send(localStorage.getItem("access_token"))
        //     }
        //
        //     webSocket.onmessage = (event) => {
        //         console.log('Socket Message !')
        //         mainStore.getCredit()
        //         this.dataAlert = JSON.parse(event.data)
        //         this.alertTrigger = !this.alertTrigger
        //     }
        //
        //     webSocket.onclose = (event) => {
        //         this.connectSocket()
        //     }
        //
        //     webSocket.onerror = (event) => {
        //         console.log('Socket Error !')
        //     }
        // },

        disConnectSocket() {
            // @ts-ignore
            this.socket.close()
        },

        initMainStore() {
            return useMainStore()
        }
    }
})
