import { default as indexIpiqeBwVrGMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/arcade/index.vue?macro=true";
import { default as indexGlxFKdsuhQMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/casino/index.vue?macro=true";
import { default as contactPf9oJLi1bLMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/contact.vue?macro=true";
import { default as _91code_93029oS9lEA1Meta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/contents/[code].vue?macro=true";
import { default as _91id_93dKUDmcRvJWMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/exchange/[id].vue?macro=true";
import { default as indexz1xtuBdprHMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/exchange/index.vue?macro=true";
import { default as indexirDIrNNvM5Meta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/fishing/index.vue?macro=true";
import { default as indexDz8h9jtm1qMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/forgotpin/index.vue?macro=true";
import { default as indexyIBPb0hIZxMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/index.vue?macro=true";
import { default as _91cat_93VGPNTg7d4LMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/lobby/[cat].vue?macro=true";
import { default as loginxzdaQ9cKglMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/login.vue?macro=true";
import { default as index8tlv7eqOWnMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/mission/index.vue?macro=true";
import { default as outgameDXeyhoikJ9Meta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/outgame.vue?macro=true";
import { default as _91code_93nxOPvlAaeFMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/promotion/[code].vue?macro=true";
import { default as indexOvuIJWreMFMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/promotion/index.vue?macro=true";
import { default as box_45randomBEnmveubzLMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/random-reward/box-random.vue?macro=true";
import { default as egg_45randomuGQ5nFUeutMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/random-reward/egg-random.vue?macro=true";
import { default as indexUuGinHVkMYMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/random-reward/index.vue?macro=true";
import { default as index1gNOhYJzWMMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/recommend/index.vue?macro=true";
import { default as registerbaaz6JC0biMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/register.vue?macro=true";
import { default as search43DntyoTHmMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/search.vue?macro=true";
import { default as edit_45profileIkfeX84uG2Meta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/setting/edit-profile.vue?macro=true";
import { default as historiesKjRlYh9fUtMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/setting/histories.vue?macro=true";
import { default as indexQWhB92SgnaMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/setting/index.vue?macro=true";
import { default as profileHXbqLj2tJzMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/setting/profile.vue?macro=true";
import { default as _91id_93EBENv9cUdwMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/slot/[name]/[id].vue?macro=true";
import { default as indexKCltohaRGfMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/slot/[name]/index.vue?macro=true";
import { default as indexUXMaZfewu7Meta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/slot/index.vue?macro=true";
import { default as indexR9FzZpgDsOMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/sport/index.vue?macro=true";
import { default as index5UsyEvzbNvMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/test/index.vue?macro=true";
import { default as _91id_938lmaVvnJOFMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/tournament/games/[id].vue?macro=true";
import { default as index9ngb33nuDMMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/tournament/games/index.vue?macro=true";
import { default as indexskF66vtSzRMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/tournament/index.vue?macro=true";
import { default as ranking_45_91tab_93yJoVKVHtkSMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/tournament/ranking-[tab].vue?macro=true";
import { default as reward8EUUGsA4Z4Meta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/tournament/reward.vue?macro=true";
import { default as verify_45bankGjyshiriMyMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/verify-bank.vue?macro=true";
import { default as indexDwRIkzjaL2Meta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/bonus/index.vue?macro=true";
import { default as QRDepositzkMt4WHscKMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/deposit/QRDeposit.vue?macro=true";
import { default as detail8oElpH0EnyMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/deposit/detail.vue?macro=true";
import { default as indexty0Y8uxnPlMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/deposit/index.vue?macro=true";
import { default as indexOldYIwRbsN6fTMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/deposit/indexOld.vue?macro=true";
import { default as indexErj9jYvKVlMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/index.vue?macro=true";
import { default as _91refCode_93AdM28RE8ToMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/slip/[refCode].vue?macro=true";
import { default as indexEYQ4cWAHkuMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/withdraw/index.vue?macro=true";
import { default as queuetmrSY4BJfAMeta } from "/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/withdraw/queue.vue?macro=true";
export default [
  {
    name: indexIpiqeBwVrGMeta?.name ?? "arcade",
    path: indexIpiqeBwVrGMeta?.path ?? "/arcade",
    meta: indexIpiqeBwVrGMeta || {},
    alias: indexIpiqeBwVrGMeta?.alias || [],
    redirect: indexIpiqeBwVrGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/arcade/index.vue").then(m => m.default || m)
  },
  {
    name: indexGlxFKdsuhQMeta?.name ?? "casino",
    path: indexGlxFKdsuhQMeta?.path ?? "/casino",
    meta: indexGlxFKdsuhQMeta || {},
    alias: indexGlxFKdsuhQMeta?.alias || [],
    redirect: indexGlxFKdsuhQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/casino/index.vue").then(m => m.default || m)
  },
  {
    name: contactPf9oJLi1bLMeta?.name ?? "contact",
    path: contactPf9oJLi1bLMeta?.path ?? "/contact",
    meta: contactPf9oJLi1bLMeta || {},
    alias: contactPf9oJLi1bLMeta?.alias || [],
    redirect: contactPf9oJLi1bLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91code_93029oS9lEA1Meta?.name ?? "contents-code",
    path: _91code_93029oS9lEA1Meta?.path ?? "/contents/:code()",
    meta: _91code_93029oS9lEA1Meta || {},
    alias: _91code_93029oS9lEA1Meta?.alias || [],
    redirect: _91code_93029oS9lEA1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/contents/[code].vue").then(m => m.default || m)
  },
  {
    name: _91id_93dKUDmcRvJWMeta?.name ?? "exchange-id",
    path: _91id_93dKUDmcRvJWMeta?.path ?? "/exchange/:id()",
    meta: _91id_93dKUDmcRvJWMeta || {},
    alias: _91id_93dKUDmcRvJWMeta?.alias || [],
    redirect: _91id_93dKUDmcRvJWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/exchange/[id].vue").then(m => m.default || m)
  },
  {
    name: indexz1xtuBdprHMeta?.name ?? "exchange",
    path: indexz1xtuBdprHMeta?.path ?? "/exchange",
    meta: indexz1xtuBdprHMeta || {},
    alias: indexz1xtuBdprHMeta?.alias || [],
    redirect: indexz1xtuBdprHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/exchange/index.vue").then(m => m.default || m)
  },
  {
    name: indexirDIrNNvM5Meta?.name ?? "fishing",
    path: indexirDIrNNvM5Meta?.path ?? "/fishing",
    meta: indexirDIrNNvM5Meta || {},
    alias: indexirDIrNNvM5Meta?.alias || [],
    redirect: indexirDIrNNvM5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/fishing/index.vue").then(m => m.default || m)
  },
  {
    name: indexDz8h9jtm1qMeta?.name ?? "forgotpin",
    path: indexDz8h9jtm1qMeta?.path ?? "/forgotpin",
    meta: indexDz8h9jtm1qMeta || {},
    alias: indexDz8h9jtm1qMeta?.alias || [],
    redirect: indexDz8h9jtm1qMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/forgotpin/index.vue").then(m => m.default || m)
  },
  {
    name: indexyIBPb0hIZxMeta?.name ?? "index",
    path: indexyIBPb0hIZxMeta?.path ?? "/",
    meta: indexyIBPb0hIZxMeta || {},
    alias: indexyIBPb0hIZxMeta?.alias || [],
    redirect: indexyIBPb0hIZxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91cat_93VGPNTg7d4LMeta?.name ?? "lobby-cat",
    path: _91cat_93VGPNTg7d4LMeta?.path ?? "/lobby/:cat()",
    meta: _91cat_93VGPNTg7d4LMeta || {},
    alias: _91cat_93VGPNTg7d4LMeta?.alias || [],
    redirect: _91cat_93VGPNTg7d4LMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/lobby/[cat].vue").then(m => m.default || m)
  },
  {
    name: loginxzdaQ9cKglMeta?.name ?? "login",
    path: loginxzdaQ9cKglMeta?.path ?? "/login",
    meta: loginxzdaQ9cKglMeta || {},
    alias: loginxzdaQ9cKglMeta?.alias || [],
    redirect: loginxzdaQ9cKglMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/login.vue").then(m => m.default || m)
  },
  {
    name: index8tlv7eqOWnMeta?.name ?? "mission",
    path: index8tlv7eqOWnMeta?.path ?? "/mission",
    meta: index8tlv7eqOWnMeta || {},
    alias: index8tlv7eqOWnMeta?.alias || [],
    redirect: index8tlv7eqOWnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/mission/index.vue").then(m => m.default || m)
  },
  {
    name: outgameDXeyhoikJ9Meta?.name ?? "outgame",
    path: outgameDXeyhoikJ9Meta?.path ?? "/outgame",
    meta: outgameDXeyhoikJ9Meta || {},
    alias: outgameDXeyhoikJ9Meta?.alias || [],
    redirect: outgameDXeyhoikJ9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/outgame.vue").then(m => m.default || m)
  },
  {
    name: _91code_93nxOPvlAaeFMeta?.name ?? "promotion-code",
    path: _91code_93nxOPvlAaeFMeta?.path ?? "/promotion/:code()",
    meta: _91code_93nxOPvlAaeFMeta || {},
    alias: _91code_93nxOPvlAaeFMeta?.alias || [],
    redirect: _91code_93nxOPvlAaeFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/promotion/[code].vue").then(m => m.default || m)
  },
  {
    name: indexOvuIJWreMFMeta?.name ?? "promotion",
    path: indexOvuIJWreMFMeta?.path ?? "/promotion",
    meta: indexOvuIJWreMFMeta || {},
    alias: indexOvuIJWreMFMeta?.alias || [],
    redirect: indexOvuIJWreMFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/promotion/index.vue").then(m => m.default || m)
  },
  {
    name: box_45randomBEnmveubzLMeta?.name ?? "random-reward-box-random",
    path: box_45randomBEnmveubzLMeta?.path ?? "/random-reward/box-random",
    meta: box_45randomBEnmveubzLMeta || {},
    alias: box_45randomBEnmveubzLMeta?.alias || [],
    redirect: box_45randomBEnmveubzLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/random-reward/box-random.vue").then(m => m.default || m)
  },
  {
    name: egg_45randomuGQ5nFUeutMeta?.name ?? "random-reward-egg-random",
    path: egg_45randomuGQ5nFUeutMeta?.path ?? "/random-reward/egg-random",
    meta: egg_45randomuGQ5nFUeutMeta || {},
    alias: egg_45randomuGQ5nFUeutMeta?.alias || [],
    redirect: egg_45randomuGQ5nFUeutMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/random-reward/egg-random.vue").then(m => m.default || m)
  },
  {
    name: indexUuGinHVkMYMeta?.name ?? "random-reward",
    path: indexUuGinHVkMYMeta?.path ?? "/random-reward",
    meta: indexUuGinHVkMYMeta || {},
    alias: indexUuGinHVkMYMeta?.alias || [],
    redirect: indexUuGinHVkMYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/random-reward/index.vue").then(m => m.default || m)
  },
  {
    name: index1gNOhYJzWMMeta?.name ?? "recommend",
    path: index1gNOhYJzWMMeta?.path ?? "/recommend",
    meta: index1gNOhYJzWMMeta || {},
    alias: index1gNOhYJzWMMeta?.alias || [],
    redirect: index1gNOhYJzWMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/recommend/index.vue").then(m => m.default || m)
  },
  {
    name: registerbaaz6JC0biMeta?.name ?? "register",
    path: registerbaaz6JC0biMeta?.path ?? "/register",
    meta: registerbaaz6JC0biMeta || {},
    alias: registerbaaz6JC0biMeta?.alias || [],
    redirect: registerbaaz6JC0biMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/register.vue").then(m => m.default || m)
  },
  {
    name: search43DntyoTHmMeta?.name ?? "search",
    path: search43DntyoTHmMeta?.path ?? "/search",
    meta: search43DntyoTHmMeta || {},
    alias: search43DntyoTHmMeta?.alias || [],
    redirect: search43DntyoTHmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/search.vue").then(m => m.default || m)
  },
  {
    name: edit_45profileIkfeX84uG2Meta?.name ?? "setting-edit-profile",
    path: edit_45profileIkfeX84uG2Meta?.path ?? "/setting/edit-profile",
    meta: edit_45profileIkfeX84uG2Meta || {},
    alias: edit_45profileIkfeX84uG2Meta?.alias || [],
    redirect: edit_45profileIkfeX84uG2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/setting/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: historiesKjRlYh9fUtMeta?.name ?? "setting-histories",
    path: historiesKjRlYh9fUtMeta?.path ?? "/setting/histories",
    meta: historiesKjRlYh9fUtMeta || {},
    alias: historiesKjRlYh9fUtMeta?.alias || [],
    redirect: historiesKjRlYh9fUtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/setting/histories.vue").then(m => m.default || m)
  },
  {
    name: indexQWhB92SgnaMeta?.name ?? "setting",
    path: indexQWhB92SgnaMeta?.path ?? "/setting",
    meta: indexQWhB92SgnaMeta || {},
    alias: indexQWhB92SgnaMeta?.alias || [],
    redirect: indexQWhB92SgnaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: profileHXbqLj2tJzMeta?.name ?? "setting-profile",
    path: profileHXbqLj2tJzMeta?.path ?? "/setting/profile",
    meta: profileHXbqLj2tJzMeta || {},
    alias: profileHXbqLj2tJzMeta?.alias || [],
    redirect: profileHXbqLj2tJzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/setting/profile.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EBENv9cUdwMeta?.name ?? "slot-name-id",
    path: _91id_93EBENv9cUdwMeta?.path ?? "/slot/:name()/:id()",
    meta: _91id_93EBENv9cUdwMeta || {},
    alias: _91id_93EBENv9cUdwMeta?.alias || [],
    redirect: _91id_93EBENv9cUdwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/slot/[name]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKCltohaRGfMeta?.name ?? "slot-name",
    path: indexKCltohaRGfMeta?.path ?? "/slot/:name()",
    meta: indexKCltohaRGfMeta || {},
    alias: indexKCltohaRGfMeta?.alias || [],
    redirect: indexKCltohaRGfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/slot/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUXMaZfewu7Meta?.name ?? "slot",
    path: indexUXMaZfewu7Meta?.path ?? "/slot",
    meta: indexUXMaZfewu7Meta || {},
    alias: indexUXMaZfewu7Meta?.alias || [],
    redirect: indexUXMaZfewu7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/slot/index.vue").then(m => m.default || m)
  },
  {
    name: indexR9FzZpgDsOMeta?.name ?? "sport",
    path: indexR9FzZpgDsOMeta?.path ?? "/sport",
    meta: indexR9FzZpgDsOMeta || {},
    alias: indexR9FzZpgDsOMeta?.alias || [],
    redirect: indexR9FzZpgDsOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/sport/index.vue").then(m => m.default || m)
  },
  {
    name: index5UsyEvzbNvMeta?.name ?? "test",
    path: index5UsyEvzbNvMeta?.path ?? "/test",
    meta: index5UsyEvzbNvMeta || {},
    alias: index5UsyEvzbNvMeta?.alias || [],
    redirect: index5UsyEvzbNvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/test/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_938lmaVvnJOFMeta?.name ?? "tournament-games-id",
    path: _91id_938lmaVvnJOFMeta?.path ?? "/tournament/games/:id()",
    meta: _91id_938lmaVvnJOFMeta || {},
    alias: _91id_938lmaVvnJOFMeta?.alias || [],
    redirect: _91id_938lmaVvnJOFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/tournament/games/[id].vue").then(m => m.default || m)
  },
  {
    name: index9ngb33nuDMMeta?.name ?? "tournament-games",
    path: index9ngb33nuDMMeta?.path ?? "/tournament/games",
    meta: index9ngb33nuDMMeta || {},
    alias: index9ngb33nuDMMeta?.alias || [],
    redirect: index9ngb33nuDMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/tournament/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexskF66vtSzRMeta?.name ?? "tournament",
    path: indexskF66vtSzRMeta?.path ?? "/tournament",
    meta: indexskF66vtSzRMeta || {},
    alias: indexskF66vtSzRMeta?.alias || [],
    redirect: indexskF66vtSzRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/tournament/index.vue").then(m => m.default || m)
  },
  {
    name: ranking_45_91tab_93yJoVKVHtkSMeta?.name ?? "tournament-ranking-tab",
    path: ranking_45_91tab_93yJoVKVHtkSMeta?.path ?? "/tournament/ranking-:tab()",
    meta: ranking_45_91tab_93yJoVKVHtkSMeta || {},
    alias: ranking_45_91tab_93yJoVKVHtkSMeta?.alias || [],
    redirect: ranking_45_91tab_93yJoVKVHtkSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/tournament/ranking-[tab].vue").then(m => m.default || m)
  },
  {
    name: reward8EUUGsA4Z4Meta?.name ?? "tournament-reward",
    path: reward8EUUGsA4Z4Meta?.path ?? "/tournament/reward",
    meta: reward8EUUGsA4Z4Meta || {},
    alias: reward8EUUGsA4Z4Meta?.alias || [],
    redirect: reward8EUUGsA4Z4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/tournament/reward.vue").then(m => m.default || m)
  },
  {
    name: verify_45bankGjyshiriMyMeta?.name ?? "verify-bank",
    path: verify_45bankGjyshiriMyMeta?.path ?? "/verify-bank",
    meta: verify_45bankGjyshiriMyMeta || {},
    alias: verify_45bankGjyshiriMyMeta?.alias || [],
    redirect: verify_45bankGjyshiriMyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/verify-bank.vue").then(m => m.default || m)
  },
  {
    name: indexDwRIkzjaL2Meta?.name ?? "wallet-bonus",
    path: indexDwRIkzjaL2Meta?.path ?? "/wallet/bonus",
    meta: indexDwRIkzjaL2Meta || {},
    alias: indexDwRIkzjaL2Meta?.alias || [],
    redirect: indexDwRIkzjaL2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/bonus/index.vue").then(m => m.default || m)
  },
  {
    name: QRDepositzkMt4WHscKMeta?.name ?? "wallet-deposit-QRDeposit",
    path: QRDepositzkMt4WHscKMeta?.path ?? "/wallet/deposit/QRDeposit",
    meta: QRDepositzkMt4WHscKMeta || {},
    alias: QRDepositzkMt4WHscKMeta?.alias || [],
    redirect: QRDepositzkMt4WHscKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/deposit/QRDeposit.vue").then(m => m.default || m)
  },
  {
    name: detail8oElpH0EnyMeta?.name ?? "wallet-deposit-detail",
    path: detail8oElpH0EnyMeta?.path ?? "/wallet/deposit/detail",
    meta: detail8oElpH0EnyMeta || {},
    alias: detail8oElpH0EnyMeta?.alias || [],
    redirect: detail8oElpH0EnyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/deposit/detail.vue").then(m => m.default || m)
  },
  {
    name: indexty0Y8uxnPlMeta?.name ?? "wallet-deposit",
    path: indexty0Y8uxnPlMeta?.path ?? "/wallet/deposit",
    meta: indexty0Y8uxnPlMeta || {},
    alias: indexty0Y8uxnPlMeta?.alias || [],
    redirect: indexty0Y8uxnPlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/deposit/index.vue").then(m => m.default || m)
  },
  {
    name: indexOldYIwRbsN6fTMeta?.name ?? "wallet-deposit-indexOld",
    path: indexOldYIwRbsN6fTMeta?.path ?? "/wallet/deposit/indexOld",
    meta: indexOldYIwRbsN6fTMeta || {},
    alias: indexOldYIwRbsN6fTMeta?.alias || [],
    redirect: indexOldYIwRbsN6fTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/deposit/indexOld.vue").then(m => m.default || m)
  },
  {
    name: indexErj9jYvKVlMeta?.name ?? "wallet",
    path: indexErj9jYvKVlMeta?.path ?? "/wallet",
    meta: indexErj9jYvKVlMeta || {},
    alias: indexErj9jYvKVlMeta?.alias || [],
    redirect: indexErj9jYvKVlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: _91refCode_93AdM28RE8ToMeta?.name ?? "wallet-slip-refCode",
    path: _91refCode_93AdM28RE8ToMeta?.path ?? "/wallet/slip/:refCode()",
    meta: _91refCode_93AdM28RE8ToMeta || {},
    alias: _91refCode_93AdM28RE8ToMeta?.alias || [],
    redirect: _91refCode_93AdM28RE8ToMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/slip/[refCode].vue").then(m => m.default || m)
  },
  {
    name: indexEYQ4cWAHkuMeta?.name ?? "wallet-withdraw",
    path: indexEYQ4cWAHkuMeta?.path ?? "/wallet/withdraw",
    meta: indexEYQ4cWAHkuMeta || {},
    alias: indexEYQ4cWAHkuMeta?.alias || [],
    redirect: indexEYQ4cWAHkuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/withdraw/index.vue").then(m => m.default || m)
  },
  {
    name: queuetmrSY4BJfAMeta?.name ?? "wallet-withdraw-queue",
    path: queuetmrSY4BJfAMeta?.path ?? "/wallet/withdraw/queue",
    meta: queuetmrSY4BJfAMeta || {},
    alias: queuetmrSY4BJfAMeta?.alias || [],
    redirect: queuetmrSY4BJfAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3739732015/src/UpbetxGame/pages/wallet/withdraw/queue.vue").then(m => m.default || m)
  }
]