export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width,user-scalable=no,initial-scale=1,minimum-scale=1,viewport-fit=cover"},{"name":"description","content":"UPBETX APP"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"},{"name":"renderer","content":"webkit"},{"name":"robots","content":"robots"},{"name":"robots","content":"Googlebot-News"},{"name":"Googlebot-News","content":"Googlebot-News"},{"name":"full-screen","content":"yes"},{"name":"x5-fullscreen","content":"true"},{"name":"360-fullscreen","content":"true"},{"name":"screen-orientation","content":"landscape"},{"name":"x5-orientation","content":"landscape"},{"name":"x5-page-mode","content":"app"},{"name":"force-rendering","content":"webkit"},{"name":"msapplication-tap-highlight","content":"no"},{"http-equiv":"Content-Security-Policy","content":"upgrade-insecure-requests"},{"name":"format-detection","content":"telephone=yes"}],"link":[],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"th"},"title":"UPBETX ทางเข้า"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const devPagesDir = null

export const devRootDir = null