import {defineStore} from "pinia";
import {api} from "~/composables/api";
import {ResponseData} from "~/types/ReponseData";

export const useTournament = defineStore("tournamentStore", {
    state: () => {
        return {
            before: {},
            countJoin: 0,
            isRegisterTournament: false,
            myRank: {},
            topWin: {},
            tournamentActivity: {},
            tournamentMember: {},
        }
    },
    actions: {
        setBefore(before: any): void {
            this.before = before
        },
        setCountJoin(countJoin: number): void {
            this.countJoin = countJoin
        },
        setIsRegisterTournament(isRegisterTournament: boolean): void {
            this.isRegisterTournament = isRegisterTournament
        },
        setMyRank(myRank: any): void {
            this.myRank = myRank
        },
        setTopWin(topWin: any): void {
            this.topWin = topWin
        },
        setTournamentActivity(tournamentActivity: any): void {
            this.tournamentActivity = tournamentActivity
        },
        setTournamentMember(tournamentMember: any): void {
            this.tournamentMember = tournamentMember
        },
        getTournament: async (): Promise<ResponseData> => {
            return await api.get({
                path: '/tournaments'
            })
        },
        getTournamentRanking: async (): Promise<ResponseData> => {
            return await api.get({
                path: '/tournaments/rankings'
            })
        },
        getTournamentGameList: async (): Promise<ResponseData> => {
            return await api.get({
                path: '/tournaments/games'
            })
        },
        getLaunchGame: async (game: any): Promise<ResponseData> => {
            return await api.post({
                path: '/tournaments/launchGame',
                body: {
                    gameCode: game.gameCode,
                    gameType: game.gameType,
                    gameId: game.gameId,
                    gameName: game.gameName,
                    gameProvider: game.gameProvider
                }
            })
        },
        joinTournament: async (): Promise<ResponseData> => {
            return await api.get({
                path: '/tournaments/register'
            })
        }
    }
})
