import {defineStore} from "pinia";
import {api} from "~/composables/api";
import {ButtonController} from "~/types/ButtonController";

export interface ControllerStoreState {
    buttonControllers: ButtonController[]

}

export const useController = defineStore("controllerStore", {
    state: (): ControllerStoreState => {
        return {
            buttonControllers: [
                // {
                //     id: 1,
                //     name: "login",
                //     desc: 'ปุ่มเข้าสู่ระบบ',
                //     status: true,
                //     path: "/login"
                // },
                // {
                //     id: 2,
                //     name: "register",
                //     desc: 'ปุ่มสมัครสมาชิก',
                //     status: true,
                //     path: "/register"
                // },
                // {
                //     id: 3,
                //     name: "wallet",
                //     desc: 'ปุ่มกระเป๋าเงิน',
                //     status: true,
                //     path: "/wallet"
                // },
                // {
                //     id: 4,
                //     name: "withdraw",
                //     desc: 'ปุ่มถอนเงิน',
                //     status: true,
                //     path: "/wallet/withdraw"
                // },
                // {
                //     id: 5,
                //     name: "deposit",
                //     status: true,
                //     desc: 'ปุ่มฝากเงิน',
                //     path: "/wallet/deposit"
                // },
                // {
                //     id: 6,
                //     name: "bonus",
                //     desc: 'ปุ่มโบนัส',
                //     status: true,
                //     path: "/wallet/bonus"
                // },
                // {
                //     id: 7,
                //     name: "auto",
                //     desc: 'ปุ่มฝากเงินอัตโนมัติ',
                //     status: true,
                //     path: "/wallet/deposit/detail"
                // },
                // {
                //     id: 8,
                //     name: "decimal",
                //     desc: 'ปุ่มฝากเงินทศนิยม',
                //     status: false,
                //     path: "/wallet/deposit/detail"
                // },
                // {
                //     id: 9,
                //     name: "slip",
                //     desc: 'ปุ่มอัพโหลดสลิป',
                //     status: false,
                //     path: "/wallet/deposit/detail"
                // },
                // {
                //     id: 10,
                //     name: "slip",
                //     desc: 'เมนูภาระกิจ',
                //     status: true,
                //     path: "/mission"
                // },
                // {
                //     id: 11,
                //     name: "slip",
                //     desc: 'เมนูกล่องสุ่ม',
                //     status: true,
                //     path: "/random-reward"
                // },
                // {
                //     id: 12,
                //     name: "slip",
                //     desc: 'ปุ่มร้านค้า',
                //     status: true,
                //     path: "/exchange"
                // },
                // {
                //     id: 13,
                //     name: "slip",
                //     desc: 'ปุ่มแข่งขันทัวร์นาเมนต์',
                //     status: false,
                //     path: "/tournament"
                // },
            ]
        }
    },
    getters: {
        getButtonControllers(): ButtonController[] {
            return this.buttonControllers
        }
    },
    actions: {
        async getSetting() {
            const setting = await api.get({
                path: '/settings'
            })
            if (setting.success) {
                this.buttonControllers = setting.data
            }
        },
    }
})
