import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src3739732015/src/UpbetxGame/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src3739732015/src/UpbetxGame/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3739732015/src/UpbetxGame/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/codebuild/output/src3739732015/src/UpbetxGame/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src3739732015/src/UpbetxGame/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src3739732015/src/UpbetxGame/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import quasar_plugin_G9rpXFQbul from "/codebuild/output/src3739732015/src/UpbetxGame/.nuxt/quasar-plugin.mjs";
import provide_4gUmUdUSZU from "/codebuild/output/src3739732015/src/UpbetxGame/node_modules/nuxt-quasar-ui/dist/runtime/provide.mjs";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src3739732015/src/UpbetxGame/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src3739732015/src/UpbetxGame/node_modules/nuxt/dist/app/plugins/payload.client.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  quasar_plugin_G9rpXFQbul,
  provide_4gUmUdUSZU,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl
]