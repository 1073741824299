import {defineStore} from "pinia";
import {api} from "~/composables/api";
import {GameStoreData} from "~/types/GameStoreData";
import {ResponseData} from "~/types/ReponseData";
import {ProviderGameData} from "~/types/ProviderGameData";
import {GameData} from "~/types/GameData";

interface bodyGameList {
    searchText?: string,
    filter: string,
    page: number,
    size: number,
    sort: string,
    prefix: string,
}

// @ts-ignore
// @ts-ignore
export const useGame = defineStore("gameStore", {
    state: (): GameStoreData => ({
        providerSelected: '',
        gameType: 'slot',
        fieldNameFilter: '',
        valueFilter: '',
        searchText: '',
        sortString: '',
        totalPage: 0,
        gameList: [],
        page: 1,
        mode: 'normal',
        isGet: false,
        filterMapList: '',
        size: 18,
        isPlaying: false,
        pathBeforeLoginGame: '',
        isRepair: false,
        isShowAlertRepair: false
    }),
    actions: {
        showAlertRepair(): void {
            this.isShowAlertRepair = true
        },
        hideAlertRepair(): void {
            this.isShowAlertRepair = false
        },
        clearPathBeforeLogin() {
            this.pathBeforeLoginGame = ''
            localStorage.removeItem('pathBeforeLoginGame')
        },

        setIsPlaying(isPlaying: boolean): void {
            this.isPlaying = isPlaying
        },

        setSize(size: number): void {
            this.size = size
        },

        setIsGet(isGet: boolean): void {
            this.isGet = isGet
        },

        setTotalPage(totalPage: number): void {
            this.totalPage = totalPage
        },

        setPage(page: number): void {
            this.page = page
        },

        setSortString(sortString: string): void {
            if (sortString) {
                this.sortString = 'order.' + sortString
            } else {
                this.sortString = ''
            }
        },

        setFieldNameFilter(fieldName: string): void {
            this.fieldNameFilter = fieldName
        },

        setValueFilter(value: string): void {
            this.valueFilter = value
            this.mapFieldNameAndValue()
        },

        mapFieldNameAndValue(): void {
            const listFieldName: string[] = this.fieldNameFilter.split(',')
            const listValue: string[] = this.valueFilter.split(',')
            const listFilterMap: any[] = []
            for (let i: number = 0; i < listFieldName.length; i++) {
                listFilterMap.push(
                    `${listFieldName[i]}:${listValue[i]}`
                )
            }
            this.filterMapList = listFilterMap.join(',')
        },

        setTextSearch(text: string): void {
            this.searchText = text
        },

        setProviderSelected(provider: string): void {
            this.providerSelected = provider
        },

        setGameType(gameType: string): void {
            this.gameType = gameType
        },

        playGameNewTab(item: GameData): void {
            if (this.isRepair) {
                this.showAlertRepair()
            } else {
                const origin = window.location.origin
                const path = `${origin}/slot/${item.providerCode || item.code}/${item.uuid}?redirect=TRUE`
                const a = document.createElement('a')
                a.href = path
                a.target = '_blank'
                a.click()
            }
        },

        playGameNormal(item: GameData, router: any): void {
            router.push(`/slot/${item?.providerCode ? item?.providerCode : item?.code}/${item.uuid}`)
        },

        isEcoMode(): boolean {
            return localStorage.getItem('ecoMode') === 'true'
        },

        async getGameProviderList(): Promise<ResponseData> {
            const isLogin = localStorage.getItem('isLogin') === 'y'
            const body: bodyGameList = {
                filter: this.filterMapList,
                page: 1,
                size: 100,
                sort: 'order',
                prefix: isLogin ? '' : localStorage.getItem('agentPrefix') || '',
            }
            if (isLogin) {
                //@ts-ignore
                delete body.prefix
            }

            return await api.post({
                path: '/games/provider',
                body: body
            })
        },

        async getGameList(): Promise<ResponseData> {
            const isLogin = localStorage.getItem('isLogin') === 'y'
            const body: bodyGameList = {
                searchText: this.searchText,
                filter: this.filterMapList,
                sort: this.sortString,
                page: this.page,
                size: this.size,
                prefix: isLogin ? '' : localStorage.getItem('agentPrefix') || '',
            }
            if (isLogin) {
                //@ts-ignore
                delete body.prefix
            }
            return await api.post({
                path: '/games/list',
                body: body
            })
        },

        async launchGame(uuid: string, router: any, route: any): Promise<any> {
            if (localStorage.getItem('pathBeforeLoginGame') &&
                this.pathBeforeLoginGame == '' &&
                localStorage.getItem('pathBeforeLoginGame') != 'null' &&
                localStorage.getItem('pathBeforeLoginGame') != 'undefined') {
                this.pathBeforeLoginGame = localStorage.getItem('pathBeforeLoginGame') || ''
            } else {
                this.pathBeforeLoginGame = router.options.history.state.back
                localStorage.setItem('pathBeforeLoginGame', this.pathBeforeLoginGame)
            }
            return await api.post({
                path: '/users/launchGame',
                body: {
                    // provider: provider,
                    uuid,
                }
            })
        },

        async likeGame(item: GameData): Promise<ResponseData> {
            return await api.post({
                path: '/games/activeFavGame',
                body: {
                    uuid: item.uuid,
                }
            })
        }
    }
})
