export const useBankList = () => {
    return [
        {
            bank_code: "TMN",
            bank_name_th: "ทรูมันนี่",
            image: "/bank/Tr.png",
            bg_color: "#ff8000",
            color: "#ffffff",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "TOPPAY",
            bank_name_th: "พร้อมเพย์",
            image: "/bank/thai-qr.png",
            bg_color: "#1a3663",
            color: "#ffffff",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "BBL",
            bank_name_th: "ธนาคารกรุงเทพ",
            image: "/bank/bbl.jpg",
            bg_color: "#282f7f",
            color: "#fff",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "KBANK",
            bank_name_th: "ธนาคารกสิกรไทย",
            image: "/bank/kbank.jpg",
            bg_color: "#008941",
            color: "#fff",
            url_open_app: 'kbank.kplus://',

        },
        {
            bank_code: "KTB",
            bank_name_th: "ธนาคารกรุงไทย",
            image: "/bank/ktb.jpg",
            bg_color: "#00a3e4",
            color: "#fff",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "BAY",
            bank_name_th: "ธนาคารกรุงศรีอยุธยา",
            image: "/bank/bay.jpg",
            bg_color: "#6f5f61",
            color: "#fff",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "GSB",
            bank_name_th: "ธนาคารออมสิน",
            image: "/bank/gsb.jpg",
            bg_color: "#ec058f",
            color: "#fff",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "GHB",
            bank_name_th: "ธนาคารอาคารสงเคราะห์",
            image: "/bank/ghb.jpg",
            bg_color: "#f7850d",
            color: "#fff",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "BAAC",
            bank_name_th: "ธ.ก.ส.",
            image: "/bank/baac.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "SCB",
            bank_name_th: "ธนาคารไทยพาณิชย์",
            image: "/bank/scb.jpg",
            bg_color: "#532882",
            color: "#fff",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "TTB",
            bank_name_th: "ธนาคารทหารไทยธนชาต",
            image: "/bank/ttb.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "ICBC",
            bank_name_th: "อินดัสเตรียล",
            image: "/bank/icbc.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "UOBT",
            bank_name_th: "ธนาคารยูโอบี (ไทย)",
            image: "/bank/uobt.jpg",
            bg_color: "#0f2c7a",
            color: "#fff",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "MEGA",
            bank_name_th: "Mega International Commercial Bank",
            image: "/bank/mega.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "CACIB",
            bank_name_th: "Credit Agricole",
            image: "/bank/cacib.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "HSBC",
            bank_name_th: "ฮ่องกงและเซี่ยงไฮ้แบงกิ้งคอร์ปอเรชั่น",
            image: "/bank/hsbc.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "DB",
            bank_name_th: "ดอยช์แบงก์",
            image: "/bank/db.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "BOA",
            bank_name_th: "ธนาคารแห่งอเมริกา",
            image: "/bank/boa.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "MB",
            bank_name_th: "ธนาคารมิซูโฮ",
            image: "/bank/mb.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "BNP",
            bank_name_th: "BNP Paribas",
            image: "/bank/bnp.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "IBANK",
            bank_name_th: "ธนาคารอิสลามแห่งประเทศไทย",
            image: "/bank/ibank.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "TISCO",
            bank_name_th: "ธนาคารทิสโก้",
            image: "/bank/tisco.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "KK",
            bank_name_th: "ธนาคารเกียรตินาคิน",
            image: "/bank/kk.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "TCRB",
            bank_name_th: "ธนาคารไทยเครดิตเพื่อรายย่อย",
            image: "/bank/tcrb.jpg",
            bg_color: "#173ba7",
            color: "#fff",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "LHB",
            bank_name_th: "ธนาคารแลนด์แอนด์เฮ้าส์",
            image: "/bank/lhb.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "RBS",
            bank_name_th: "ธนาคารแห่งสกอตแลนด์",
            image: "/bank/rbs.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "SC",
            bank_name_th: "สแตนดาร์ดชาร์เตอร์ (ไทย)",
            image: "/bank/sc.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "CIMB",
            bank_name_th: "ธนาคารซีไอเอ็มบีไทย",
            image: "/bank/cimb.jpg",
            bg_color: "#780001",
            color: "#fff",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "JPM",
            bank_name_th: "เจ.พี.มอร์แกน",
            image: "/bank/jpm.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "MUFG",
            bank_name_th: "ธนาคารแห่งโตเกียว - มิตซูบิชิยูเอฟเจ",
            image: "/bank/mufg.jpg",
            bg_color: "#fff",
            color: "#000",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "CITI",
            bank_name_th: "ธนาคารซิตี้แบงก์",
            image: "/bank/citi.jpg",
            bg_color: "#1c54a1",
            color: "#fff",
            url_open_app: 'https://google.co.th/',
        },
        {
            bank_code: "SMBC",
            bank_name_th: "ซูมิโตโม มิตซุย แบงกิ้ง คอร์ปอเรชั่น",
            image: "/bank/smbc.jpg",
            bg_color: "#124b36",
            color: "#fff",
            url_open_app: 'https://google.co.th/',
        },
    ]
}
