<template>
  <div id="main-wrap" :class="{ 'page-fadeUp-transition-exit': loading >= 50}">
    <!--    <div class="flex justify-center items-center" style="height: 100dvh">-->
    <!--      <q-img src="https://upbetx.s3.ap-southeast-1.amazonaws.com/images/game-app/close-update.jpg"-->
    <!--             style="max-width: 500px;width: 90dvw"></q-img>-->
    <!--    </div>-->
    <NuxtLayout />
    <AlertRepair />
  </div>
</template>
<script setup>
import { useMainStore } from '~/store/mainStore'
import { useGame } from '~/store/gameStore'
import { useQuasar } from 'quasar'
import { useSocket } from '~/store/socketStore'
import { useController } from '~/store/controllerStore'

const $q = useQuasar()
$q.loading.show()
const mainStore = useMainStore()
const router = useRouter()
const route = useRoute()
const game = useGame()
const socket = useSocket()
const loading = ref(0)
const interval = ref(0)
const controller = useController()
const bufferValue = ref(50)

onBeforeMount(() => {
  controller.getSetting()
  startBuffer()
  setTimeout(() => {
    loading.value = 100
    clearInterval(interval.value)
  }, 500)
  const preloader = document.getElementById('preloader')
  if (preloader !== null || undefined) {
    preloader.remove()
  }
})

onMounted(async () => {
  checkCasinoPath(route.path)
  if (localStorage.getItem('isLogin') && localStorage.getItem('isLogin') === 'y') {
    if (localStorage.getItem('agentPrefix')) {
      mainStore.setUrlQueryPrefix(localStorage.getItem('agentPrefix'))
      mainStore.getPrefix()
    }
    await mainStore.getProfile()
    $q.loading.hide()
  } else {
    checkLocalStorageAgentPrefix()
  }
})

watch(() => socket.alertTrigger, () => {
  if (socket.dataAlert?.success) {
    mainStore.getProfile()
    mainStore.getCredit()
    if (!game.isPlaying) {
      if (socket.dataAlert?.message === 'setting') {
        controller.getSetting()
      } else {
        notifications('success', socket.dataAlert?.message)
      }
      if (socket.dataAlert?.type === 'withdraw') {
        router.push('/wallet')
      } else {
        router.push('/')
      }

    }
  }
})

watch(() => route.path, (val, old) => {
  if (val !== '/') {
    if (old !== '/wallet/deposit/detail') {
      socket.closeAlert()
    }
  }
  if (route.name === 'slot-name-id') {
    if (game.isRepair) {
      game.showAlertRepair()
    } else {
      game.hideAlertRepair()
    }
  }
  if (localStorage.getItem('isLogin') && localStorage.getItem('isLogin') === 'y') {
    mainStore.getCredit()
  }
  checkCasinoPath(val)
})

watch(() => $q.screen.lt.sm, (val) => {
  checkCasinoPath(route.path)
})

const checkLocalStorageAgentPrefix = () => {
  if (
      localStorage.getItem('agentPrefix') === 'undefined' ||
      localStorage.getItem('agentPrefix') === 'null' ||
      !localStorage.getItem('agentPrefix') &&
      localStorage.getItem('agentPrefix')) {
    localStorage.removeItem('agentPrefix')
    localStorage.removeItem('referralBy')
    localStorage.removeItem('referral')
  } else if (
      localStorage.getItem('urlQueryPrefix') === 'undefined' ||
      localStorage.getItem('urlQueryPrefix') === 'null' ||
      !localStorage.getItem('urlQueryPrefix') &&
      localStorage.getItem('urlQueryPrefix')) {
    localStorage.removeItem('urlQueryPrefix')
    localStorage.removeItem('referralBy')
    localStorage.removeItem('referral')
  } else {
    if (localStorage.getItem('agentPrefix') === route.query.prefix) {
      console.log('Is Agent Match')
    } else {
      console.log('Is Agent Not Match')
      localStorage.removeItem('referralBy')
      localStorage.removeItem('referral')
    }
    checkQueryParams()
    $q.loading.hide()
  }

}
const startBuffer = () => {
  clearInterval(interval.value)
  interval.value = setInterval(() => {
    loading.value += Math.random() * ( 15 - 5 ) + 5
    bufferValue.value += Math.random() * ( 15 - 5 ) + 6
  }, 100)
}
const checkQueryParams = async () => {
  if (route.query?.referralBy && route.query?.recommend) {
    mainStore.setReferralBy(route.query?.referralBy)
    mainStore.setReferral(route.query?.recommend)
    mainStore.countClickLink()
  }
  checkReferralBy()
  if (localStorage.getItem('referral')) {
    if (localStorage.getItem('referral') !== route.query?.recommend) {
      localStorage.setItem('referral', route.query?.recommend)
      mainStore.setReferral(route.query?.recommend)
    } else {
      mainStore.setReferral(localStorage.getItem('referral'))
    }
    mainStore.setUrlQueryPrefix(route.query?.prefix?.toUpperCase() || '')
    await mainStore.getPrefix()
    router.push('/')
  } else {
    if (route.query?.prefix || localStorage.getItem('agentPrefix')) {
      mainStore.setUrlQueryPrefix(route.query?.prefix?.toUpperCase() || '')
      await mainStore.getPrefix()
      if (route.query?.recommend) {
        router.push('/')
        localStorage.setItem('referral', route.query?.recommend)
        mainStore.setReferral(route.query?.recommend)

      } else {
        mainStore.setReferral('')
      }
    }
  }
}

const checkReferralBy = () => {
  // http://localhost:3001/?prefix=UBX&recommend=UBX0989810815&openExternalBrowser=1&referralBy=Youtube
  if (route.query?.referralBy) {
    localStorage.setItem('referralBy', route.query?.referralBy)
    mainStore.setReferralBy(route.query?.referralBy)
  } else {
    if (localStorage.getItem('referralBy')) {
      mainStore.setReferralBy(localStorage.getItem('referralBy'))
    } else {
      mainStore.setReferralBy('')
      localStorage.removeItem('referralBy')
    }
  }
}
const checkCasinoPath = (val) => {
  if (val === '/casino') {
    // document.body.style.backgroundColor = '#6e36d8'
    document.body.style.backgroundColor = '#6e36d8'
    if ($q.screen.lt.sm) {
      document.body.style.backgroundImage = 'url(\'https://opacity.mo.cloudinary.net/small/background/bg-casino-4.png\')'
      // document.body.style.backgroundImage = 'url(\'https://upbetx.s3.ap-southeast-1.amazonaws.com/images/theme/happynewyear/background01.png\')'
    } else {
      document.body.style.backgroundImage = 'url(\'https://opacity.mo.cloudinary.net/small/theme/background_lobby.webp\')'

    }
  } else {
    // OLD
    document.body.style.backgroundColor = '#034773'
    if ($q.screen.lt.sm) {
      document.body.style.backgroundImage = 'url(\'https://opacity.mo.cloudinary.net/small/theme/portage-background-2.webp\')'
    } else {
      document.body.style.backgroundImage = 'url(\'https://opacity.mo.cloudinary.net/small/theme/background_lobby.webp\')'
    }

    //new year
    // document.body.style.backgroundColor = '#7d0f06'
    // if ($q.screen.lt.sm) {
    //   document.body.style.backgroundImage = 'url(\'https://upbetx.s3.ap-southeast-1.amazonaws.com/images/theme/happynewyear/background01.png\')'
    // } else {
    //   document.body.style.backgroundImage = 'url(\'https://upbetx.s3.ap-southeast-1.amazonaws.com/images/theme/happynewyear/background-fullscreen.jpg\')'
    // }
  }
}
</script>
