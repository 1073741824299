import {Notify} from "quasar";

export default function notifications(status: string, message: string): void {
    Notify.create({
        message: message,
        color: status === 'success' ? 'positive' : 'negative',
        html: true,
        icon: status === 'success' ? 'check_circle' : 'cancel',
        closeBtn: 'X',
        timeout: status === 'success' ? 0 : 4000,
    })
}
