<template>
  <q-dialog :model-value='game.isShowAlertRepair'>
    <q-card class="full-width shadow-10" style="border-radius: 12px">
      <q-card-section class="q-dialog__title text-center q-py-sm text-red">
        ปิดปรับปรุงระบบประจำสัปดาห์ !
      </q-card-section>
      <q-separator />
      <q-card-section>
        <p class="text-red text-weight-medium text-center">
          ขณะนี้ทางผู้ให้บริการค่ายเกมทำการปิดปรับปรุงระบบประจำสัปดาห์ จึงส่งผลให้ไม่สามารถเล่นเกมได้ในขณะนี้ <br>
          เวลาปิดปรับปรุงประมาณ 3 ชั่วโมง ขออภัยในความไม่สะดวกค่ะ
        </p>
      </q-card-section>
      <q-separator />
      <q-card-section class="text-center q-py-sm">
        <q-btn color="orange-8" push @click="closeAlert">ปิด</q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import {useGame} from "~/store/gameStore";

const props = defineProps({
  isShowAlert: {
    type: Boolean,
    default: false,
  },
});
const game = useGame()

const closeAlert = () => {
  game.hideAlertRepair()
}

</script>

<style lang="scss" scoped>

</style>
