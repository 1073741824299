import {defineStore} from "pinia";
import {useMainStore} from "~/store/mainStore";
import {useBankList} from "~/composables/bankList";
import {api} from "~/composables/api";
import {BankData} from "~/types/BankData";
import {BankDepositData} from "~/types/BankDepositData";
import {BankSelectionData} from "~/types/BankSelectionData";
import {ResponseData} from "~/types/ReponseData";
import {BankHistoryData} from "~/types/BankHistoryData";

interface DataBankVerify extends BankData {
    bank_code: string;
    bank_name_th: string;
    image: string;
    bg_color: string;
    color: string;
    url_open_app: string;
}


export const useBank = defineStore("bankStore", {
    state: (): {
        bankDepositList: BankSelectionData[];
        bankDefault: [{
            bank_code: "SCB",
            bankCode: "SCB",
            bank_name_th: "ธนาคารไทยพาณิชย์",
            image: "/bank/scb.jpg",
            bg_color: "#532882",
            color: "#fff",
            url_open_app: 'https://google.co.th/',
        }, {
            bank_code: "TMN",
            bankCode: "TMN",
            bank_name_th: "ทรูมันนี่",
            image: "/bank/Tr.png",
            bg_color: "#ff8000",
            color: "#ffffff",
            url_open_app: 'https://google.co.th/',
        }, {
            bank_code: "TOPPAY",
            bankCode: "TOPPAY",
            bank_name_th: "พร้อมเพย์",
            image: "/bank/thai-qr.png",
            bg_color: "#1a3663",
            color: "#ffffff",
            url_open_app: 'https://google.co.th/',
        }]
        dataBankVerify: {
            bankCode: string;
            bank_code: string;
            bank_name_th: string;
            image: string;
            updatedBy: string;
            color: string;
            url_open_app: string;
            totalDeposit: number;
            prefix: string;
            totalWithdraw: number;
            searchKbank: string;
            fullName: string;
            createdAt: number;
            bg_color: string;
            createdBy: string;
            accountNo: string;
            searchScb: string;
            customerId: string;
            fullNameKbank: any[];
            updatedAt: number
        };
        bankHistory: BankHistoryData[];
        bankAccountNumber: string;
        loadingTransfer: boolean;
        DataBankVerify: undefined;
        bankDepositSelection: { bankNumber: string; bankCode: string; fullName: string; bankName: string };
        bankData: {
            bank_code: string;
            bank_name_th: string;
            image: string;
            bg_color: string;
            color: string;
            url_open_app: string;
        }
    } => {
        return {
            DataBankVerify: undefined,
            bankDefault: [
                {
                    bank_code: "SCB",
                    bankCode: "SCB",
                    bank_name_th: "ธนาคารไทยพาณิชย์",
                    image: "/bank/scb.jpg",
                    bg_color: "#532882",
                    color: "#fff",
                    url_open_app: 'https://google.co.th/',
                },
                {
                    bank_code: "TMN",
                    bankCode: "TMN",
                    bank_name_th: "ทรูมันนี่",
                    image: "/bank/Tr.png",
                    bg_color: "#ff8000",
                    color: "#ffffff",
                    url_open_app: 'https://google.co.th/',
                }, {
                    bank_code: "TOPPAY",
                    bankCode: "TOPPAY",
                    bank_name_th: "พร้อมเพย์",
                    image: "/bank/thai-qr.png",
                    bg_color: "#1a3663",
                    color: "#ffffff",
                    url_open_app: 'https://google.co.th/',
                }
            ],
            bankAccountNumber: '',
            bankData: {
                bank_code: "",
                bank_name_th: "",
                image: "",
                bg_color: "",
                color: "",
                url_open_app: "",
            },
            dataBankVerify: {
                totalDeposit: 0,
                bankCode: "",
                createdAt: 0,
                prefix: "",
                updatedBy: "",
                totalWithdraw: 0,
                fullName: "",
                createdBy: "",
                accountNo: "",
                searchScb: "",
                searchKbank: "",
                updatedAt: 0,
                fullNameKbank: [],
                customerId: "",
                bank_code: "",
                bank_name_th: "",
                image: "",
                bg_color: "",
                color: "",
                url_open_app: "",
            },
            bankDepositList: [],
            loadingTransfer: false,
            bankDepositSelection: {
                bankNumber: "",
                bankCode: "",
                fullName: "",
                bankName: "",
            },
            bankHistory: []
        }
    },
    actions: {
        setBankAccountNumber(bankAccountNumber: string): void {
            this.bankAccountNumber = bankAccountNumber
        },

        setBackDepositSelection(bankDepositSelection: BankDepositData): void {
            this.bankDepositSelection = bankDepositSelection
        },

        setDataBankVerify(dataBankVerify: BankData): void {
            const bankList: BankSelectionData[] = useBankList() as BankSelectionData[]
            this.dataBankVerify = {...bankList.find(bank => bank.bank_code === dataBankVerify.bankCode), ...dataBankVerify} as DataBankVerify
        },

        setBankData(bankData: BankSelectionData): void {
            this.bankData = bankData
        },

        checkVerifyBank(router: any, path: string): void {
            const mainStore = useMainStore()
            if (mainStore.profile?.bankVerifyStatus === 'TRUE') {
                router.push(path)
            } else {
                router.push('/')
            }
        },

        setBankDepositList(bankDepositList: BankDepositData[]): void {
            this.bankDepositList = bankDepositList.map(item => {
                const bank: BankSelectionData = useBankList().find(bankList => bankList.bank_code === item.bankCode) as BankSelectionData
                return {
                    ...bank,
                    ...item
                }
            })
        },

        async verifyBank(): Promise<ResponseData> {
            const mainStore = useMainStore();
            return await api.post({
                path: "/users/verifyBank",
                body: {
                    prefix: mainStore.profile?.prefix,
                    phone: mainStore.profile?.phone,
                    bankCode: this.bankData.bank_code,
                    bankNumber: this.bankAccountNumber,
                    verifyBank: true
                }
            });
        },

        async getBankList(): Promise<ResponseData> {
            return await api.get({
                path: '/banks/deposit'
            })
        },

        async getBankTransferList(): Promise<ResponseData> {
            this.loadingTransfer = true
            const res = await api.post({
                path: '/banks/history',
                body: {
                    type: 'transfer',
                    page: 1,
                    size: 100
                }
            })
            if (res.success) {
                this.bankHistory = res?.data?.data || [] as BankHistoryData[]
            }
            this.loadingTransfer = false
            return res
        },

        async verifyKbank(nameEn: string): Promise<ResponseData> {
            return await api.post({
                path: "/users/verifyKbank",
                body: {
                    fullNameEn: nameEn.toUpperCase(),
                }
            });
        },
        async triggerBankForDeposit(): Promise<ResponseData> {
            return await api.get({
                path: "/banks/trigger",
                query: {
                    accountNo: this.bankDepositSelection.bankNumber,
                },
            });
        }
    }
})
